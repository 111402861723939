import service from '@/utils/request';

// @Tags Dnssec
// @Summary 创建Dnssec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Dnssec true "创建Dnssec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /dnssec/enableDnssec [post]
export var enableDnssec = function enableDnssec(params) {
  return service({
    url: "/dnssec/enableDnssec",
    method: 'post',
    params: params
  });
};

// @Tags Dnssec
// @Summary 删除Dnssec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Dnssec true "删除Dnssec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /dnssec/disableDnssec [delete]
export var disableDnssec = function disableDnssec(params) {
  return service({
    url: "/dnssec/disableDnssec",
    method: 'delete',
    params: params
  });
};

// @Tags Dnssec
// @Summary 用id查询Dnssec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Dnssec true "用id查询Dnssec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /dnssec/getDnssecInfo [get]
export var getDnssecInfo = function getDnssecInfo(params) {
  return service({
    url: "/dnssec/getDnssecInfo",
    method: 'get',
    params: params
  });
};

// @Tags Dnssec
// @Summary 分页获取Dnssec列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Dnssec列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /dnssec/getDnssecList [get]
export var getDnssecList = function getDnssecList(params) {
  return service({
    url: "/dnssec/getDnssecList",
    method: 'get',
    params: params
  });
};